<template>
  <div>
    <b-modal v-model="showModalLocal" @hidden="onCancel" hide-footer>
      <b-card>
        <b-progress :value="percent" :max="100" show-value show-progress animated />
        <input type="file" v-if="!uploading" @input="upload" label="Upload" />
      </b-card>
    </b-modal>
  </div>
</template>

<script>
import { BModal, BCard, BProgress } from "bootstrap-vue";
import { TokenService } from "@/apiServices/storageService";

export default {
  components: {
    BModal,
    BCard,
    BProgress,
  },
  props: {
    showModal: {
      default: false,
      type: Boolean,
    },
    url_path: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      showModalLocal: false,
      uploading: false,
      percent: 0,
      file: null,
      data: null,
      controller: null,
    };
  },
  watch: {
    showModal(val) {
      // if(val) {
      this.showModalLocal = this.showModal;
      // }
    },
  },
  methods: {
    reset() {
      this.percent = 0;
      this.file = null;
      this.data = null;
      this.uploading = false;
      if (this.controller) {
        this.controller.abort();
      }
    },
    onCancel(e) {
      this.reset();
      this.$emit("cancel");
    },
    async upload(e) {
      const file = e.target.files[0];

      try {
        const token = TokenService.getToken();

        if (!token) {
          alert("Error in getting token");
        }

        const formData = new FormData();

        formData.append("excel", file);

        this.uploading = true;

        this.controller = new AbortController();
        const { signal } = this.controller;

        const response = await fetch(
          `${process.env.VUE_APP_BASEURL}/${this.url_path}?authorization=${token}`,
          {
            headers: {
              'Transfer-Encoding': 'chunked'
            },
            body: formData,
            method: "POST",
            signal,
          }
        );

        const reader = response.body.getReader();

        while (true) {
          const data = await reader.read();
          const jsonStr = String.fromCharCode.apply(null, data.value);

          const json = JSON.parse(jsonStr);

          if (typeof json.status === 'boolean' && !json.status) {
            alert(json.message)
            this.onCancel()
            return
          }

          if (json.error) {
            alert(json.message);
            this.controller = null;
            this.onCancel();
            return;
          }

          if (json.finished) {
            this.percent = 100;
            break;
          }
          if (json.total && json.index) {
            this.percent = parseFloat(
              ((json.index * 100) / json.total).toFixed(1)
            );
          }
          if (json.data) {
            this.data = json.data;
          }
        }
        this.$emit('done')
        this.controller = null;
        this.onCancel();
      } catch (error) {
        console.error("Error in upload ", error);
        this.onCancel();
        alert("Error in excel upload");
        this.$emit("error");
      }
    },
  },
};
</script>
  
<style></style>
  